<template>
  <div class="lg:ml-12 px-4 w-full">
    <user-header></user-header>
    <div class="lg:hidden flex justify-center mt-10">
      <router-link :to="{ name: 'referral' }">
        <m-button
          button-style="btn-primary"
          segmentName="Invite to Meetric"
          :segmentProps="{ from: 'User header' }"
          title="Send colleagues a Meetric invite via email"
          icon="👩🏼‍💻"
        >
          Invite people to Meetric
        </m-button>
      </router-link>
    </div>
    <div class="lg:w-4/5 mx-auto">
      <div class="mt-8 lg:mt-0" v-if="defer(0)">
        <profile-section-title>
          <template v-slot:heading><div class="text-xl">Account</div></template>
          <template v-slot:subtitle>
            <div v-if="accountPlan == 0">
              You're currently on the
              <span class="font-bold">basic</span> plan - free forever!
            </div>
            <div v-if="accountPlan == 1">
              You're currently on the
              <span class="font-bold">basic</span> plan - free forever!
            </div>
            <div v-if="accountPlan > 1">
              You're currently on the
              <span v-if="accountPlan == 2" class="font-bold"
                >Meetric Pro Individual</span
              >
              <span v-if="accountPlan == 3" class="font-bold"
                >Meetric Pro Team</span
              >
              plan
              <span v-if="accountPlanCancel">
                - cancelled at the end of your billing cycle ({{ cancelDate }})
              </span>
              <span v-if="accountPlanTrial && trialEnd">
                - free trial until {{ trialEnd }}
              </span>
            </div>
          </template>
        </profile-section-title>

        <preference-item v-if="accountPlan < 3">
          <template v-slot:icon>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              width="24"
              height="24"
              stroke="none"
            >
              <path
                d="M4.873 3h14.254a1 1 0 0 1 .809.412l3.823 5.256a.5.5 0 0 1-.037.633L12.367 21.602a.5.5 0 0 1-.706.028c-.007-.006-3.8-4.115-11.383-12.329a.5.5 0 0 1-.037-.633l3.823-5.256A1 1 0 0 1 4.873 3zm.51 2l-2.8 3.85L12 19.05 21.417 8.85 18.617 5H5.383z"
              />
            </svg>
          </template>

          <template v-slot:title>Meetric Pro Individual</template>
          <template v-slot:subtitle>
            Unlimited meetings, history and templates -
            <a-link
              segmentName="Learn pro"
              href="https://meetric.notion.site/Meetric-Pro-231289c089e948b79f235c006e83c582"
              >Learn more</a-link
            >
          </template>
          <template v-slot:control>
            <m-button
              v-if="accountPlan < 2"
              @click="initSubscribe"
              buttonStyle="btn-tertiary"
              segmentName="subscribe"
              :pending="loadSubscribe"
              icon="💎"
            >
              Subscribe
            </m-button>
            <m-button
              v-if="
                (subscriptionEra == 0 &&
                  accountPlan > 1 &&
                  !accountPlanCancel &&
                  !accountPlanTrial) ||
                (subscriptionEra == 1 && accountPlan > 1 && !accountPlanCancel)
              "
              @click="updateCC"
              buttonStyle="btn-tertiary"
              segmentName="updateCC"
              icon="💳"
              class="mr-2"
            >
              Update CC
            </m-button>
            <m-button
              v-if="
                (subscriptionEra == 0 &&
                  accountPlan > 1 &&
                  !accountPlanCancel &&
                  !accountPlanTrial) ||
                (subscriptionEra == 1 && accountPlan > 1 && !accountPlanCancel)
              "
              @click="cancelSubscribe"
              buttonStyle="btn-tertiary"
              segmentName="cancelSubscription"
              :pending="loadSubscribe"
              icon="❌"
            >
              Cancel
            </m-button>
            <m-button
              v-if="
                accountPlan > 1 &&
                accountPlanCancel &&
                (!accountPlanTrial ||
                  (subscriptionEra == 1 && accountPlanTrial))
              "
              @click="uncancelSubscribe"
              buttonStyle="btn-tertiary"
              segmentName="uncancelSubscription"
              :pending="loadUncancelSubscribe"
              icon="💎"
            >
              Reactivate
            </m-button>
            <m-button
              v-if="subscriptionEra == 0 && accountPlan > 1 && accountPlanTrial"
              @click="initSubscribe"
              buttonStyle="btn-tertiary"
              segmentName="subscribe"
              :pending="loadSubscribe"
              icon="💎"
            >
              Upgrade
            </m-button>
            <!--<m-button
              v-if="accountPlan > 0 && accountPlanCancel"
              class="ml-2"
              @click="cancelSubscriptionNow"
              buttonStyle="btn-tertiary"
              segmentName="cancelSubscriptionNow"
              :pending="loadCancelSubscribeNow"
              icon=""
            >
              Cancel now
            </m-button>-->
          </template>
        </preference-item>
        <preference-item>
          <template v-slot:icon>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              width="24"
              height="24"
              stroke="none"
            >
              <path
                d="M4.873 3h14.254a1 1 0 0 1 .809.412l3.823 5.256a.5.5 0 0 1-.037.633L12.367 21.602a.5.5 0 0 1-.706.028c-.007-.006-3.8-4.115-11.383-12.329a.5.5 0 0 1-.037-.633l3.823-5.256A1 1 0 0 1 4.873 3zm.51 2l-2.8 3.85L12 19.05 21.417 8.85 18.617 5H5.383z"
              />
            </svg>
          </template>

          <template v-slot:title>Meetric Pro Team</template>
          <template v-slot:subtitle>
            All Pro features for the whole team -
            <a-link
              segmentName="Learn team pro"
              href="https://meetric.notion.site/Meetric-Pro-Team-33a00e7b38ee4e0b9829a5b4b9577da2"
              >Learn more</a-link
            >
          </template>
          <template v-slot:control>
            <m-button
              v-if="accountPlan <= 3"
              @click="changeRouteTo('team-subscription')"
              buttonStyle="btn-tertiary"
              segmentName="subscribeTeam"
              icon="💎"
            >
              Team setup
            </m-button>
          </template>
        </preference-item>
        <preference-item v-if="colleaguesCount > 1">
          <template v-slot:icon>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              width="24"
              height="24"
              stroke="none"
            >
              <path
                d="M2 22a8 8 0 1 1 16 0h-2a6 6 0 1 0-12 0H2zm8-9c-3.315 0-6-2.685-6-6s2.685-6 6-6 6 2.685 6 6-2.685 6-6 6zm0-2c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm8.284 3.703A8.002 8.002 0 0 1 23 22h-2a6.001 6.001 0 0 0-3.537-5.473l.82-1.824zm-.688-11.29A5.5 5.5 0 0 1 21 8.5a5.499 5.499 0 0 1-5 5.478v-2.013a3.5 3.5 0 0 0 1.041-6.609l.555-1.943z"
              />
            </svg>
          </template>

          <template v-slot:title>Colleagues</template>
          <template v-slot:subtitle>
            Who else in my team is using Meetric
          </template>

          <template v-slot:control>
            <router-link :to="{ name: 'company' }">
              <m-button buttonStyle="btn-tertiary" icon="👀"> View </m-button>
            </router-link>
          </template>
        </preference-item>
        <preference-item>
          <template v-slot:icon>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              width="24"
              height="24"
              stroke="none"
            >
              <path
                d="M14 14.252v2.09A6 6 0 0 0 6 22l-2-.001a8 8 0 0 1 10-7.748zM12 13c-3.315 0-6-2.685-6-6s2.685-6 6-6 6 2.685 6 6-2.685 6-6 6zm0-2c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm6.586 6l-1.829-1.828 1.415-1.415L22.414 18l-4.242 4.243-1.415-1.415L18.586 19H15v-2h3.586z"
              />
            </svg>
          </template>

          <template v-slot:title>Share the love</template>
          <template v-slot:subtitle>
            Invite others <span v-if="accountPlan > 1">to Meetric</span>
            <span v-else>and earn 30 days of free Meetric Pro</span>
          </template>

          <template v-slot:control>
            <router-link :to="{ name: 'referral' }">
              <m-button buttonStyle="btn-tertiary" icon="💌"> Invite </m-button>
            </router-link>
          </template>
        </preference-item>
      </div>

      <div class="mt-10 lg:mt-8" v-if="defer(1)">
        <profile-section-title>
          <template v-slot:heading
            ><div class="text-xl">Integrations</div></template
          >
          <template v-slot:subtitle>Use Meetric with your other apps</template>
        </profile-section-title>

        <preference-item v-if="!isExtension">
          <template v-slot:icon>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink"
              viewBox="1 1 176 176"
              width="24"
              height="24"
            >
              <defs>
                <circle id="A" cx="96" cy="96" r="88" />
                <path id="B" d="M8 184h83.77l38.88-38.88V116h-69.3L8 24.48z" />
              </defs>
              <clipPath id="C"><use xlink:href="#A" /></clipPath>
              <g class="B" transform="translate(-7 -7)">
                <path d="M21.97 8v108h39.4L96 56h88V8z" fill="#db4437" />
                <linearGradient
                  id="D"
                  x1="29.34"
                  x2="81.84"
                  y1="75.02"
                  y2="44.35"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stop-color="#a52714" stop-opacity=".6" offset="0" />
                  <stop stop-color="#a52714" stop-opacity="0" offset=".66" />
                </linearGradient>
                <path d="M21.97 8v108h39.4L96 56h88V8z" fill="url(#D)" />
                <path
                  d="M62.3 115.6L22.48 47.3l-.58 1 39.54 67.8z"
                  class="C D"
                />
                <use xlink:href="#B" fill="#0f9d58" />
                <linearGradient
                  id="E"
                  x1="110.9"
                  x2="52.54"
                  y1="164.5"
                  y2="130.3"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stop-color="#055524" stop-opacity=".4" offset="0" />
                  <stop stop-color="#055524" stop-opacity="0" offset=".33" />
                </linearGradient>
                <path
                  d="M8 184h83.77l38.88-38.88V116h-69.3L8 24.48z"
                  fill="url(#E)"
                />
                <path
                  d="M129.8 117.3l-.83-.48-38.4 67.15h1.15l38.1-66.64z"
                  fill="#263238"
                  class="D"
                />
                <defs>
                  <path
                    id="F"
                    d="M8 184h83.77l38.88-38.88V116h-69.3L8 24.48z"
                  />
                </defs>
                <clipPath id="G"><use xlink:href="#F" /></clipPath>
                <g clip-path="url(#G)">
                  <path d="M96 56l34.65 60-38.88 68H184V56z" fill="#ffcd40" />
                  <linearGradient
                    id="H"
                    x1="121.9"
                    x2="136.6"
                    y1="49.8"
                    y2="114.1"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop stop-color="#ea6100" stop-opacity=".3" offset="0" />
                    <stop stop-color="#ea6100" stop-opacity="0" offset=".66" />
                  </linearGradient>
                  <path d="M96 56l34.65 60-38.88 68H184V56z" fill="url(#H)" />
                </g>
                <path d="M96 56l34.65 60-38.88 68H184V56z" fill="#ffcd40" />
                <path d="M96 56l34.65 60-38.88 68H184V56z" fill="url(#H)" />
                <defs>
                  <path id="I" d="M96 56l34.65 60-38.88 68H184V56z" />
                </defs>
                <clipPath id="J"><use xlink:href="#I" /></clipPath>
                <g clip-path="url(#J)">
                  <path d="M21.97 8v108h39.4L96 56h88V8z" fill="#db4437" />
                  <path d="M21.97 8v108h39.4L96 56h88V8z" fill="url(#D)" />
                </g>
              </g>
              <radialGradient
                id="K"
                cx="668.2"
                cy="55.95"
                r="84.08"
                gradientTransform="translate(-576)"
                gradientUnits="userSpaceOnUse"
              >
                <stop stop-color="#3e2723" stop-opacity=".2" offset="0" />
                <stop stop-color="#3e2723" stop-opacity="0" offset="1" />
              </radialGradient>
              <g transform="translate(-7 -7)">
                <path d="M96 56v20.95L174.4 56z" fill="url(#K)" class="B" />
                <g class="B">
                  <defs>
                    <path id="L" d="M21.97 8v40.34L61.36 116 96 56h88V8z" />
                  </defs>
                  <clipPath id="M"><use xlink:href="#L" /></clipPath>
                  <g clip-path="url(#M)">
                    <use xlink:href="#B" fill="#0f9d58" />
                    <path
                      d="M8 184h83.77l38.88-38.88V116h-69.3L8 24.48z"
                      fill="url(#E)"
                    />
                  </g>
                </g>
              </g>
              <radialGradient
                id="N"
                cx="597.9"
                cy="48.52"
                r="78.04"
                gradientTransform="translate(-576)"
                gradientUnits="userSpaceOnUse"
              >
                <stop stop-color="#3e2723" stop-opacity=".2" offset="0" />
                <stop stop-color="#3e2723" stop-opacity="0" offset="1" />
              </radialGradient>
              <path
                transform="translate(-7 -7)"
                d="M21.97 48.45l57.25 57.24L61.36 116z"
                fill="url(#N)"
                class="B"
              />
              <radialGradient
                id="O"
                cx="671.8"
                cy="96.14"
                r="87.87"
                gradientTransform="translate(-576)"
                gradientUnits="userSpaceOnUse"
              >
                <stop stop-color="#263238" stop-opacity=".2" offset="0" />
                <stop stop-color="#263238" stop-opacity="0" offset="1" />
              </radialGradient>
              <g transform="translate(-7 -7)">
                <path
                  d="M91.83 183.9l20.96-78.2 17.86 10.3z"
                  fill="url(#O)"
                  class="B"
                />
                <g class="B">
                  <circle cx="96" cy="96" r="40" fill="#f1f1f1" />
                  <circle cx="96" cy="96" r="32" fill="#4285f4" />
                  <path
                    d="M96 55c-22.1 0-40 17.9-40 40v1c0-22.1 17.9-40 40-40h88v-1H96z"
                    class="C E"
                  />
                  <path
                    d="M130.6 116c-6.92 11.94-19.8 20-34.6 20-14.8 0-27.7-8.06-34.6-20h-.04L8 24.48v1L61.4 117c6.92 11.94 19.8 20 34.6 20 14.8 0 27.68-8.05 34.6-20h.05v-1h-.06z"
                    fill="#fff"
                    fill-opacity=".1"
                  />
                  <path
                    d="M97 56c-.17 0-.33.02-.5.03C118.36 56.3 136 74.08 136 96s-17.64 39.7-39.5 39.97c.17 0 .33.03.5.03 22.1 0 40-17.9 40-40s-17.9-40-40-40z"
                    opacity=".1"
                    class="C"
                  />
                  <path
                    d="M131 117.3c3.4-5.88 5.37-12.68 5.37-19.96a39.87 39.87 0 0 0-1.87-12.09c.95 3.42 1.5 7 1.5 10.73 0 7.28-1.97 14.08-5.37 19.96l.02.04-38.88 68h1.16L131 117.3z"
                    fill="#fff"
                    class="E"
                  />
                  <path
                    d="M96 9c48.43 0 87.72 39.13 88 87.5 0-.17.01-.33.01-.5 0-48.6-39.4-88-88-88S8 47.4 8 96c0 .17.01.33.01.5C8.28 48.13 47.57 9 96 9z"
                    fill="#fff"
                    class="E"
                  />
                  <path
                    d="M96 183c48.43 0 87.72-39.13 88-87.5 0 .17.01.33.01.5 0 48.6-39.4 88-88 88S8 144.6 8 96c0-.17.01-.33.01-.5.27 48.37 39.56 87.5 88 87.5z"
                    class="C D"
                  />
                </g>
              </g>
            </svg>
          </template>

          <template v-slot:title> Chrome extension </template>
          <template v-slot:subtitle>
            Take notes within a side panel on any page, including video
            conferencing windows
          </template>

          <template v-slot:control>
            <a-link
              v-if="!extensionInstalled"
              nolinkstyle
              segmentName="Install extension"
              href="https://chrome.google.com/webstore/detail/meetric/imjdlmoaemnjongiaddmdgmpnomppdml"
            >
              <m-button buttonStyle="btn-tertiary" icon="🔧">
                Install
              </m-button>
            </a-link>
            <div v-else class="italic text-grey3 text-base">Installed</div>
          </template>
        </preference-item>

        <preference-item>
          <template v-slot:icon>
            <svg
              height="24"
              width="24"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="12 0.19 487.619 510.941"
            >
              <path
                class="stroke-current text-base1"
                d="M96.085 91.118c15.81 12.845 21.741 11.865 51.43 9.884l279.888-16.806c5.936 0 1-5.922-.98-6.906L379.94 43.686c-8.907-6.915-20.773-14.834-43.516-12.853L65.408 50.6c-9.884.98-11.858 5.922-7.922 9.883zm16.804 65.228v294.491c0 15.827 7.909 21.748 25.71 20.769l307.597-17.799c17.81-.979 19.794-11.865 19.794-24.722V136.57c0-12.836-4.938-19.758-15.84-18.77l-321.442 18.77c-11.863.997-15.82 6.931-15.82 19.776zm303.659 15.797c1.972 8.903 0 17.798-8.92 18.799l-14.82 2.953v217.412c-12.868 6.916-24.734 10.87-34.622 10.87-15.831 0-19.796-4.945-31.654-19.76l-96.944-152.19v147.248l30.677 6.922s0 17.78-24.75 17.78l-68.23 3.958c-1.982-3.958 0-13.832 6.921-15.81l17.805-4.935V210.7l-24.721-1.981c-1.983-8.903 2.955-21.74 16.812-22.736l73.195-4.934 100.889 154.171V198.836l-25.723-2.952c-1.974-10.884 5.927-18.787 15.819-19.767zM42.653 23.919l281.9-20.76c34.618-2.969 43.525-.98 65.283 14.825l89.986 63.247c14.848 10.876 19.797 13.837 19.797 25.693v346.883c0 21.74-7.92 34.597-35.608 36.564L136.64 510.14c-20.785.991-30.677-1.971-41.562-15.815l-66.267-85.978C16.938 392.52 12 380.68 12 366.828V58.495c0-17.778 7.922-32.608 30.653-34.576z"
                fill-rule="evenodd"
              />
            </svg>
          </template>
          <template v-slot:title>Notion (beta)</template>
          <template v-slot:subtitle>Save meeting notes in Notion</template>
          <template v-slot:control>
            <m-button
              v-if="!notionConnected"
              @click="connectNotion"
              buttonStyle="btn-tertiary"
              segmentName="Connect Notion"
              icon="🔗"
            >
              Connect
            </m-button>
            <m-button
              class="ml-2"
              v-if="notionConnected"
              @click="changeRouteTo('notionselect')"
              buttonStyle="btn-tertiary"
              segmentName="Notion settings"
              icon="⚙️"
            >
              Settings
            </m-button>
          </template>
        </preference-item>

        <preference-item>
          <template v-slot:icon>
            <svg
              height="24"
              width="24"
              version="1.1"
              id="Layer_1"
              xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink"
              x="0px"
              y="0px"
              viewBox="57 57 150 150"
              xml:space="preserve"
            >
              <g>
                <g>
                  <path
                    fill="#e01e5a"
                    d="M99.4,151.2c0,7.1-5.8,12.9-12.9,12.9c-7.1,0-12.9-5.8-12.9-12.9c0-7.1,5.8-12.9,12.9-12.9h12.9V151.2z"
                  />
                  <path
                    fill="#e01e5a"
                    d="M105.9,151.2c0-7.1,5.8-12.9,12.9-12.9s12.9,5.8,12.9,12.9v32.3c0,7.1-5.8,12.9-12.9,12.9
			s-12.9-5.8-12.9-12.9V151.2z"
                  />
                </g>
                <g>
                  <path
                    fill="#36c5f0"
                    d="M118.8,99.4c-7.1,0-12.9-5.8-12.9-12.9c0-7.1,5.8-12.9,12.9-12.9s12.9,5.8,12.9,12.9v12.9H118.8z"
                  />
                  <path
                    fill="#36c5f0"
                    d="M118.8,105.9c7.1,0,12.9,5.8,12.9,12.9s-5.8,12.9-12.9,12.9H86.5c-7.1,0-12.9-5.8-12.9-12.9
			s5.8-12.9,12.9-12.9H118.8z"
                  />
                </g>
                <g>
                  <path
                    fill="#2eb67d"
                    d="M170.6,118.8c0-7.1,5.8-12.9,12.9-12.9c7.1,0,12.9,5.8,12.9,12.9s-5.8,12.9-12.9,12.9h-12.9V118.8z"
                  />
                  <path
                    fill="#2eb67d"
                    d="M164.1,118.8c0,7.1-5.8,12.9-12.9,12.9c-7.1,0-12.9-5.8-12.9-12.9V86.5c0-7.1,5.8-12.9,12.9-12.9
			c7.1,0,12.9,5.8,12.9,12.9V118.8z"
                  />
                </g>
                <g>
                  <path
                    fill="#ecb22e"
                    d="M151.2,170.6c7.1,0,12.9,5.8,12.9,12.9c0,7.1-5.8,12.9-12.9,12.9c-7.1,0-12.9-5.8-12.9-12.9v-12.9H151.2z"
                  />
                  <path
                    fill="#ecb22e"
                    d="M151.2,164.1c-7.1,0-12.9-5.8-12.9-12.9c0-7.1,5.8-12.9,12.9-12.9h32.3c7.1,0,12.9,5.8,12.9,12.9
			c0,7.1-5.8,12.9-12.9,12.9H151.2z"
                  />
                </g>
              </g>
            </svg>
          </template>
          <template v-slot:title>Slack (beta)</template>
          <template v-slot:subtitle
            >Send meeting notes to a Slack channel</template
          >
          <template v-slot:control>
            <m-button
              v-if="!slackConnected"
              @click="connectSlack"
              buttonStyle="btn-tertiary"
              segmentName="Connect Slack"
              icon="🔗"
            >
              Connect
            </m-button>
            <m-button
              v-if="slackConnected"
              @click="changeRouteTo('slackselectdefault')"
              buttonStyle="btn-tertiary"
              segmentName="Slack settings"
              icon="⚙️"
            >
              Settings
            </m-button>
          </template>
        </preference-item>

        <preference-item>
          <template v-slot:icon>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              width="24"
              height="24"
            >
              <path
                d="M5.463 4.433A9.961 9.961 0 0 1 12 2c5.523 0 10 4.477 10 10 0 2.136-.67 4.116-1.81 5.74L17 12h3A8 8 0 0 0 6.46 6.228l-.997-1.795zm13.074 15.134A9.961 9.961 0 0 1 12 22C6.477 22 2 17.523 2 12c0-2.136.67-4.116 1.81-5.74L7 12H4a8 8 0 0 0 13.54 5.772l.997 1.795z"
              />
            </svg>
          </template>
          <template v-slot:title>Other</template>
          <template v-slot:subtitle>What else would you like to see?</template>
          <template v-slot:control>
            <a-link
              nolinkstyle
              segmentName="Integration preferences"
              href="https://meetricapp.typeform.com/to/JH2KFXfS"
            >
              <m-button icon="💬" buttonStyle="btn-tertiary">
                Let us know
              </m-button>
            </a-link>
          </template>
        </preference-item>
      </div>

      <div class="mt-10 lg:mt-8" v-if="defer(2)">
        <profile-section-title>
          <template v-slot:heading
            ><div class="text-xl">Preferences</div></template
          >
          <template v-slot:subtitle>Customise Meetric to your liking</template>
        </profile-section-title>
        <div>
          <!-- Dark mode -->
          <preference-item>
            <template v-slot:icon>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                width="24"
                height="24"
                stroke="none"
              >
                <path
                  d="M10 7a7 7 0 0 0 12 4.9v.1c0 5.523-4.477 10-10 10S2 17.523 2 12 6.477 2 12 2h.1A6.979 6.979 0 0 0 10 7zm-6 5a8 8 0 0 0 15.062 3.762A9 9 0 0 1 8.238 4.938 7.999 7.999 0 0 0 4 12z"
                />
              </svg>
            </template>
            <template v-slot:title>Set dark mode</template>
            <template v-slot:subtitle>Make it easier on the eyes</template>
            <template v-slot:control
              ><m-select
                class="ml-4 text-grey1 font-medium"
                @input="setPreference('dark_mode', darkValue)"
                v-model="darkValue"
                :options="darkOptions"
                :pending="localPreferenceDarkMode != darkValue"
                segmentName="Set dark mode"
              ></m-select
            ></template>
          </preference-item>

          <preference-item>
            <template v-slot:icon>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                width="24"
                height="24"
                stroke="none"
              >
                <path
                  d="M3 3h18a1 1 0 0 1 1 1v16a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1zm17 8H4v8h16v-8zm0-2V5H4v4h16zM9 6h2v2H9V6zM5 6h2v2H5V6z"
                />
              </svg>
            </template>
            <template v-slot:title>Set start page</template>
            <template v-slot:subtitle
              >Select the page that first opens in Meetric</template
            >
            <template v-slot:control>
              <m-select
                class="ml-4 text-grey1 font-medium"
                @input="setPreference('start_page', viewValue)"
                v-model="viewValue"
                :options="defaultViewOptions"
                :pending="localPreferenceStartPage != viewValue"
                segmentName="Set start page"
              ></m-select>
            </template>
          </preference-item>

          <preference-item>
            <template v-slot:icon>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                width="24"
                height="24"
                stroke="none"
              >
                <path
                  d="M4 22a8 8 0 1 1 16 0h-2a6 6 0 1 0-12 0H4zm8-9c-3.315 0-6-2.685-6-6s2.685-6 6-6 6 2.685 6 6-2.685 6-6 6zm0-2c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4z"
                />
              </svg>
            </template>
            <template v-slot:title>Show solo events</template>
            <template v-slot:subtitle
              >Show events with no guests in Meetings list</template
            >
            <template v-slot:control>
              <m-toggle
                @input="setPreference('show_solo', soloValue)"
                v-model="soloValue"
                :pending="localPreferenceShowSolo != soloValue"
                segmentName="Show solo events"
              ></m-toggle>
            </template>
          </preference-item>

          <preference-item>
            <template v-slot:icon>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                width="24"
                height="24"
                stroke="none"
              >
                <path
                  d="M17 3h4a1 1 0 0 1 1 1v16a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1h4V1h2v2h6V1h2v2zm3 6V5h-3v2h-2V5H9v2H7V5H4v4h16zm0 2H4v8h16v-8zM6 13h5v4H6v-4z"
                />
              </svg>
            </template>
            <template v-slot:title>Show all-day events</template>
            <template v-slot:subtitle
              >Show events without specified times in Meetings list</template
            >
            <template v-slot:control
              ><m-toggle
                @input="setPreference('show_all_day', allDayValue)"
                v-model="allDayValue"
                :pending="localPreferenceShowAllDay != allDayValue"
                segmentName="Show all-day events"
              ></m-toggle
            ></template>
          </preference-item>

          <preference-item v-if="accountPlan > 1">
            <template v-slot:icon>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                width="24"
                height="24"
                stroke="none"
              >
                <path
                  d="M17 13a4 4 0 1 1-4 4h-2a4 4 0 1 1-.535-2h3.07A3.998 3.998 0 0 1 17 13zM7 15a2 2 0 1 0 0 4 2 2 0 0 0 0-4zm10 0a2 2 0 1 0 0 4 2 2 0 0 0 0-4zM16 3a4 4 0 0 1 4 4v3h2v2H2v-2h2V7a4 4 0 0 1 4-4h8zm0 2H8c-1.054 0-2 .95-2 2v3h12V7c0-1.054-.95-2-2-2z"
                />
              </svg>
            </template>

            <template v-slot:title>Open 'Just me' notes by default</template>
            <template v-slot:subtitle
              >Open private notes instead of shared team notes</template
            >
            <template v-slot:control
              ><m-toggle
                @input="setPreference('new_note_option', newNoteOption)"
                v-model="newNoteOption"
                :pending="localPreferenceNewNoteOption != newNoteOption"
                segmentName="Open 'Just me' by default"
              ></m-toggle
            ></template>
          </preference-item>

          <preference-item>
            <template v-slot:icon>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="icon icon-tabler icon-tabler-mail"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                fill="none"
                stroke-linecap="round"
                stroke-linejoin="round"
              >
                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                <rect x="3" y="5" width="18" height="14" rx="2" />
                <polyline points="3 7 12 13 21 7" />
              </svg>
            </template>
            <template v-slot:title>Subscribe to the daily email</template>
            <template v-slot:subtitle
              >Prepare tomorrow's meetings, excl. weekends</template
            >
            <template v-slot:control>
              <m-toggle
                v-if="hasOfflineAccess"
                v-model="dailySummaryOption"
                @input="setPreference('daily_summary', dailySummaryOption)"
                :pending="localPreferenceDailySummary != dailySummaryOption"
                segmentName="Daily email summary"
              ></m-toggle>

              <m-button
                v-else
                class=""
                @click="requestOfflineAccess"
                button-style="btn-tertiary"
                segmentName="Request offline access"
                icon="🔗"
                :pending="offlineConnecting"
              >
                Subscribe
              </m-button>
            </template>
          </preference-item>
        </div>
      </div>

      <div class="mt-10 lg:mt-8" v-if="defer(3)">
        <profile-section-title>
          <template v-slot:heading
            ><div class="text-xl">Time Machine</div></template
          >
          <template v-slot:subtitle
            >Customise meetings included in the Time Machine</template
          >
        </profile-section-title>
        <div>
          <preference-item>
            <template v-slot:icon>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                width="24"
                height="24"
                stroke="none"
              >
                <path
                  d="M8 20v1.932a.5.5 0 0 1-.82.385l-4.12-3.433A.5.5 0 0 1 3.382 18H18a2 2 0 0 0 2-2V8h2v8a4 4 0 0 1-4 4H8zm8-16V2.068a.5.5 0 0 1 .82-.385l4.12 3.433a.5.5 0 0 1-.321.884H6a2 2 0 0 0-2 2v8H2V8a4 4 0 0 1 4-4h10z"
                ></path>
              </svg>
            </template>
            <template v-slot:title>Show recurring series</template>
            <template v-slot:subtitle></template>
            <template v-slot:control
              ><m-toggle
                @input="
                  setPreference('timemachine_recurring', timemachineRecurring)
                "
                v-model="timemachineRecurring"
                :pending="
                  localPreferenceTimemachineRecurring != timemachineRecurring
                "
                segmentName="Add recurring events"
              ></m-toggle
            ></template>
          </preference-item>

          <preference-item>
            <template v-slot:icon>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                width="24"
                height="24"
                stroke="none"
              >
                <path
                  d="M2 22a8 8 0 1 1 16 0h-2a6 6 0 1 0-12 0H2zm8-9c-3.315 0-6-2.685-6-6s2.685-6 6-6 6 2.685 6 6-2.685 6-6 6zm0-2c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm8.284 3.703A8.002 8.002 0 0 1 23 22h-2a6.001 6.001 0 0 0-3.537-5.473l.82-1.824zm-.688-11.29A5.5 5.5 0 0 1 21 8.5a5.499 5.499 0 0 1-5 5.478v-2.013a3.5 3.5 0 0 0 1.041-6.609l.555-1.943z"
                ></path>
              </svg>
            </template>
            <template v-slot:title>Show meetings with same guests</template>
            <template v-slot:subtitle></template>
            <template v-slot:control
              ><m-toggle
                @input="setPreference('timemachine_guests', timemachineGuests)"
                v-model="timemachineGuests"
                :pending="localPreferenceTimemachineGuests != timemachineGuests"
                segmentName="Add events based on same guests"
              ></m-toggle
            ></template>
          </preference-item>

          <preference-item>
            <template v-slot:icon>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                width="24"
                height="24"
                stroke="none"
              >
                <path
                  d="M7 6V3a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1v14a1 1 0 0 1-1 1h-3v3c0 .552-.45 1-1.007 1H4.007A1.001 1.001 0 0 1 3 21l.003-14c0-.552.45-1 1.006-1H7zM5.002 8L5 20h10V8H5.002zM9 6h8v10h2V4H9v2zm-2 5h6v2H7v-2zm0 4h6v2H7v-2z"
                ></path>
              </svg>
            </template>
            <template v-slot:title>Show meetings with similar names </template>
            <template v-slot:subtitle></template>
            <template v-slot:control
              ><m-toggle
                @input="
                  setPreference('timemachine_summary', timemachineSummary)
                "
                v-model="timemachineSummary"
                :pending="
                  localPreferenceTimemachineSummary != timemachineSummary
                "
                segmentName="Add events based similar names"
              ></m-toggle
            ></template>
          </preference-item>
        </div>
      </div>

      <profile-section-title class="mt-8" v-if="defer(4)">
        <template v-slot:heading
          ><div class="text-xl">Connected calendars</div></template
        >
        <template v-slot:subtitle
          >Events from your synced calendars will appear in your meeting list
        </template>
      </profile-section-title>

      <!-- Calendars -->
      <user-calendars></user-calendars>

      <div class="mt-8">
        <a-link
          segmentName="Get help"
          href="https://meetric.notion.site/Meetric-Help-Center-4c5d2585197343b1910329c24b6da9fc"
          >Get help</a-link
        >
      </div>
      <router-link :to="{ name: 'signout', params: { broadcast: true } }">
        <m-button buttonStyle="btn-tertiary" class="mt-8 mb-8" icon="❌">
          Sign out</m-button
        >
      </router-link>
    </div>
  </div>
</template>

<script>
import ALink from '@/components/UI/ALink';
import MButton from '@/components/UI/MButton';
import MToggle from '@/components/UI/MToggle';
import MSelect from '@/components/UI/MSelect';
import UserHeader from '@/components/UserHeader';
import PreferenceItem from '@/components/UI/PreferenceItem';
import ProfileSectionTitle from './ProfileSectionTitle';
import UserCalendars from './UserCalendars';
import {
  setPreferences,
  subscribe,
  updatecc,
  unsubscribe,
  grantOfflineAccess,
} from '@/api/Cherry';
import { preferences } from '@/components/Utils';
import Defer from '@/mixin/Defer';

export default {
  name: 'Profile',
  components: {
    ALink,
    MButton,
    MSelect,
    MToggle,
    UserHeader,
    PreferenceItem,
    ProfileSectionTitle,
    UserCalendars,
  },
  mixins: [Defer()],
  data() {
    return {
      darkValue: '',
      viewValue: '',
      soloValue: false,
      allDayValue: false,
      newNoteOption: false,
      timemachineRecurring: false,
      timemachineGuests: false,
      timemachineSummary: false,
      dailySummaryOption: false,
      colleaguesCount: 0,
      offlineConnecting: false,
      darkOptions: [
        { value: 'system', label: 'System' },
        { value: 'light', label: 'Light' },
        { value: 'dark', label: 'Dark' },
      ],
      defaultViewOptions: [
        { value: 'meetings', label: 'Meetings list' },
        { value: 'profile', label: 'Profile' },
        { value: 'now', label: 'Next meeting' },
        { value: 'actions', label: 'Actions list' },
      ],
      notionButtonPending: false,
      loadSubscribe: false,
      loadUncancelSubscribe: false,
      loadCancelSubscribeNow: false,
    };
  },
  computed: {
    isExtension() {
      return !!this.$store?.getters['isExtension'];
    },
    localPreferenceNewNoteOption() {
      return this.$store.getters['preferenceNotesDefaultJustMe'];
    },
    localPreferenceDailySummary() {
      return this.$store.getters['preferenceDailySummary'];
    },
    localPreferenceDarkMode() {
      return this.$store.getters['preferenceDarkMode'];
    },
    localPreferenceStartPage() {
      return this.$store.getters['preferenceStartPage'];
    },
    localPreferenceShowSolo() {
      return this.$store.getters['preferenceShowSolo'];
    },
    localPreferenceShowAllDay() {
      return this.$store.getters['preferenceShowAllDay'];
    },
    localPreferenceTimemachineRecurring() {
      return this.$store.getters['preferenceTimemachineRecurring'];
    },
    localPreferenceTimemachineGuests() {
      return this.$store.getters['preferenceTimemachineGuests'];
    },
    localPreferenceTimemachineSummary() {
      return this.$store.getters['preferenceTimemachineSummary'];
    },
    localCalendars() {
      return this.$store.getters['selectedCalendars'];
    },
    extensionInstalled() {
      const body = document.querySelector('body');
      const extension = body ? body.getAttribute('data-extension') : false;
      return extension && extension == 'meetric';
    },
    accountPlan() {
      return this.$store.getters['plan'];
    },
    subscriptionEra() {
      let era = this.$store.getters['era'];
      if (!era) {
        return 0;
      }
      return this.$store.getters['era'];
    },
    accountPlanCancel() {
      return this.$store.getters['planCancel'];
    },
    cancelDate() {
      var a = new Date(this.accountPlanCancel * 1000);
      return this.dateString(a);
    },
    accountPlanTrial() {
      return this.$store.getters['planTrial'];
    },
    trialEnd() {
      var a = new Date(this.accountPlanTrial * 1000);
      if (new Date() - a >= 0) {
        return false;
      }
      return this.dateString(a);
    },
    notionDetails() {
      return this.$store.getters['notionDetails'];
    },
    notionConnected() {
      return this.notionDetails?.connected;
    },
    slackDetails() {
      return this.$store.getters['slackDetails'];
    },
    slackConnected() {
      return this.slackDetails?.connected;
    },
    hasOfflineAccess() {
      return this.$store.getters['hasOfflineAccess'];
    },
  },
  created() {
    const paymentResult = this.$route.query.payment;
    const ccupdated = this.$route.query.cc;
    if (paymentResult) {
      if (paymentResult == 'cancel') {
        this.$notification('Payment cancelled - account not upgraded', {
          closable: true,
          type: 'error',
          errorMsg: 'failed payment',
        });
      } else if (paymentResult == 'success') {
        if (this.accountPlan == 3) {
          this.$notification('🎉 You are now on the Meetric Team Pro plan', {
            closable: true,
            type: 'validation',
          });
        } else {
          this.$notification('🎉 You are now on the Meetric Pro plan', {
            closable: true,
            type: 'validation',
          });
        }
      }
    }

    if (ccupdated) {
      if (ccupdated == 'updated') {
        this.$notification('Your card has been successfully updated', {
          closable: true,
          type: 'validation',
        });
      }
    }
  },
  mounted() {
    this.$scrollToTop();
    this.initValues();
    this.getColleagues();
    this.checkTrialStatus();
  },
  methods: {
    dateString(d) {
      var months = [
        'Jan',
        'Feb',
        'Mar',
        'Apr',
        'May',
        'Jun',
        'Jul',
        'Aug',
        'Sep',
        'Oct',
        'Nov',
        'Dec',
      ];
      var year = d.getFullYear();
      var month = months[d.getMonth()];
      var date = d.getDate();
      var time = `${date} ${month} ${year}`;
      return time;
    },
    async checkTrialStatus() {
      let plan = this.$store.getters['plan'];
      let era = this.$store.getters['era'];
      let trialConsumed = this.$store.getters['trialConsumed'];
      let errorMsg = 'readonly-trialended';
      let message = '';
      if (plan == 0 && era == 1 && trialConsumed) {
        await subscribe().then((r) => {
          if (r.URL) {
            message = [
              {
                link: {
                  text: 'Reactivate your Meetric account',
                  isExternal: true,
                  to: r.URL,
                },
              },
            ];
            this.$notification(message, {
              closable: false,
              type: 'warning',
              errorMsg,
            });
          } else {
            console.error('Problem finding payment URL');
          }
        });
      }
    },
    getColleagues: function () {
      this.colleaguesCount = this.$store.getters['colleagues'].count;
    },
    requestOfflineAccess() {
      this.offlineConnecting = true;
      this.$gAuth
        .getAuthCode()
        .then((code) => {
          return grantOfflineAccess(code)
            .then((r) => {
              // response from meetric server

              if (r?.ok === true) {
                this.$store.commit('saveOfflineAccess', true);
                this.dailySummaryOption = true;
                this.setPreference('daily_summary', true);
              }
            })
            .catch(() =>
              this.$snack(
                'Error when obtaining offline access, please refrest page and try again.'
              )
            );
        })
        .catch(() =>
          this.$snack('Please grant offline access to your calendar')
        )
        .finally(() => (this.offlineConnecting = false));
    },
    initSubscribe() {
      this.loadSubscribe = true;
      subscribe()
        .then((r) => {
          if (r.URL) {
            window.location.href = r.URL;
          } else {
            this.loadSubscribe = false;
            console.error('Problem finding payment URL');
          }
        })
        .catch(() => {
          this.loadSubscribe = false;
          console.error('Subscribe initialisation failed');
        });
    },
    cancelSubscribe() {
      const r = confirm('Are you sure you want to cancel Meetric Pro?');
      if (!r) return;
      this.loadSubscribe = true;
      unsubscribe()
        .then((r) => {
          this.$store.commit('savePlan', r);
          this.loadSubscribe = false;
          this.$snack('Meetric Pro subscription cancelled');
        })
        .catch(() => {
          this.loadSubscribe = false;
          console.error('Unsubscribe failed');
        });
    },
    updateCC() {
      // this.loadSubscribe = true;
      updatecc()
        .then((r) => {
          if (r) {
            window.location.href = r;
          } else {
            console.error('Problem finding update URL');
          }
        })
        .catch(() => {
          console.error('Credit card update failed');
        });
    },
    cancelSubscriptionNow() {
      const r = confirm(
        'Are you sure you want to cancel Meetric Pro immediately?'
      );
      if (!r) return;
      this.loadCancelSubscribeNow = true;
      unsubscribe(true)
        .then((r) => {
          this.$store.commit('savePlan', r);
          this.loadCancelSubscribeNow = false;
          this.$snack('Meetric Pro subscription ended');
        })
        .catch(() => {
          this.loadCancelSubscribeNow = false;
          console.error('Unsubscribe now failed');
        });
    },
    uncancelSubscribe() {
      this.loadUncancelSubscribe = true;
      unsubscribe(false, true)
        .then((r) => {
          this.$store.commit('savePlan', r);
          this.loadUncancelSubscribe = false;
          this.$snack('Successfully reactivated account');
        })
        .catch(() => {
          this.loadUncancelSubscribe = false;
          console.error('Uncancel subscription failed');
        });
    },
    initValues() {
      if (this.$store.getters['preferencesLoaded']) {
        this.$store
          .dispatch('fetchPreferences')
          .then(() => this.updateValues());
      } else {
        this.updateValues();
      }
    },
    updateValues() {
      this.dailySummaryOption = this.localPreferenceDailySummary;
      this.darkValue = this.localPreferenceDarkMode;
      this.viewValue = this.localPreferenceStartPage;
      this.soloValue = this.localPreferenceShowSolo;
      this.allDayValue = this.localPreferenceShowAllDay;
      this.timemachineRecurring = this.localPreferenceTimemachineRecurring;
      this.timemachineGuests = this.localPreferenceTimemachineGuests;
      this.timemachineSummary = this.localPreferenceTimemachineSummary;
      this.newNoteOption = this.localPreferenceNewNoteOption;
    },
    setPreference(label, value) {
      var data = {};
      data[label] = value;
      setPreferences(data).then((result) => {
        window.meetric.track('Set preference', { [label]: value });

        if (label == 'daily_summary') {
          window.meetric.identify(this.$gAuth.basicProfile.getEmail(), {
            dailyemail: value,
          });
        }

        this.$store.commit('savePreferences', result);
        this.updateValues();
        preferences.init(result);
      });
    },
    connectNotion() {
      const url = new URL('https://api.notion.com/v1/oauth/authorize');
      const redirUrl =
        process.env.VUE_APP_PUBLIC_ENDPOINT +
        process.env.VUE_APP_NOTION_REDIRECT_URI;

      url.searchParams.append(
        'client_id',
        process.env.VUE_APP_NOTION_CLIENT_ID
      );
      url.searchParams.append('response_type', 'code');
      url.searchParams.append('redirect_uri', redirUrl);

      window.open(url.href, '_self');
    },
    changeRouteTo(name) {
      this.$router.push({
        name,
      });
    },
    connectSlack() {
      const url = new URL('https://slack.com/oauth/v2/authorize');
      const redirUrl =
        process.env.VUE_APP_PUBLIC_ENDPOINT +
        process.env.VUE_APP_SLACK_REDIRECT_URI;

      url.searchParams.append('client_id', process.env.VUE_APP_SLACK_CLIENT_ID);
      url.searchParams.append('user_scope', process.env.VUE_APP_SLACK_SCOPE);
      url.searchParams.append('redirect_uri', redirUrl);
      console.log(url.searchParams.toString());
      window.open(url.href, '_self');
    },
  },
};
</script>
<style scoped>
.B {
  clip-path: url(#C);
}
.C {
  fill: #3e2723;
}
.D {
  fill-opacity: 0.15;
}
.E {
  fill-opacity: 0.2;
}
</style>
