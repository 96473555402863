<template>
  <div class="flex flex-wrap lg:nowrap items-center">
    <!-- @slot Title label -->
    <div class="text-base1 font-medium text-lg w-full">
      <slot name="heading"></slot>
    </div>

    <div class="text-grey3 font-normal text-base w-full">
      <!-- @slot Subtitle label -->
      <slot name="subtitle"></slot>
    </div>

    <!-- Dividing line -->
    <div
      class="w-full mt-1 mb-2 bg-base2 bg-opacity-5 dark:bg-base5"
      style="height: 1px"
    />
  </div>
</template>

<script>
export default {
  name: 'ProfileSectionTitle',
  components: {},
  methods: {},
};
</script>

<style scoped></style>
